import { extractGetLeftValueIfTrue } from 'services/utils/extractGetLeftValueIfTrue';
import { t } from 'services/utils/translation';

export const getDocumentsUIText = () => {
  const getHintForSentToArchive = extractGetLeftValueIfTrue(
    t('Отправленно в архив'),
    t('Не Отправленно в архив ')
  );

  const TO_SENT_TO_ARCHIVE_TEXT = t('Отправить в архив');

  const getHintForRecivedToArchive = extractGetLeftValueIfTrue(
    t('Принято в архив'),
    t('Не принято в архив ')
  );

  const TO_RECUVED_TO_ARCHIVE_TEXT = t('Принять в архив');

  const getTitleTable = extractGetLeftValueIfTrue(
    t('Документы'),
    t('Документы для принятия в архив')
  );

  return {
    getTitleTable,
    TO_RECUVED_TO_ARCHIVE_TEXT,
    getHintForRecivedToArchive,
    TO_SENT_TO_ARCHIVE_TEXT,
    getHintForSentToArchive,
  };
};
