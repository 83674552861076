import { useCallback } from 'react';
import { ValueType } from 'react-select';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { t } from 'services/utils/translation';

export enum BooleanSelectField {
  task = 'internal_task_is_pruned',
  externalRequest = 'external_request_is_pruned',
  event = 'event_is_closed',
  billingAccount = 'billing_account_is_closed',
  counterpartyIsNatural = 'counterparty_is_natural_person',
  counterpartyIsAffiliate = 'counterparty_is_affiliate',
  controller = 'controller_is_closed',
  documentSentToArchive = 'sent_to_archive',
  documentReceivedToArchive = 'received_to_archive',
}

const archiveOptions: IClientSelectOptionV2<boolean>[] = [
  {
    value: false,
    label: t('Нет в архиве'),
  },
  { value: true, label: t('В архивe') },
];

const sentToArchiveOptions: IClientSelectOptionV2<boolean>[] = [
  {
    value: false,
    label: t('Не отправлено в архив'),
  },
  { value: true, label: t('Отправленно в архив') },
];

const receivedToArchiveOptions: IClientSelectOptionV2<boolean>[] = [
  { value: false, label: t('Не принято в архив') },
  {
    value: true,
    label: t('Принято в архив'),
  },
];

const isClosedOptions: IClientSelectOptionV2<boolean>[] = [
  {
    value: true,
    label: t('Закрыто'),
  },
  { value: false, label: t('Открыто') },
];

const isOffOptions: IClientSelectOptionV2<boolean>[] = [
  {
    value: true,
    label: t('Отключен'),
  },
  { value: false, label: t('Включен') },
];

const isNaturalPerson: IClientSelectOptionV2<boolean>[] = [
  {
    value: true,
    label: t('Физ. лицо'),
  },
  { value: false, label: t('Юр. лицо') },
];

const isAffialate: IClientSelectOptionV2<boolean>[] = [
  {
    value: true,
    label: t('Является партнером'),
  },
  { value: false, label: t('Не является партнером') },
];

const OPTIONS_MAP = {
  [BooleanSelectField.task]: archiveOptions,
  [BooleanSelectField.externalRequest]: archiveOptions,
  [BooleanSelectField.event]: isClosedOptions,
  [BooleanSelectField.billingAccount]: isClosedOptions,
  [BooleanSelectField.counterpartyIsNatural]: isNaturalPerson,
  [BooleanSelectField.counterpartyIsAffiliate]: isAffialate,
  [BooleanSelectField.controller]: isOffOptions,
  [BooleanSelectField.documentSentToArchive]: sentToArchiveOptions,
  [BooleanSelectField.documentReceivedToArchive]: receivedToArchiveOptions,
};

// TODO: remove flag old

type PropsType = {
  onSearchRequest: (searchString: any, field: BooleanSelectField) => void;
  field: BooleanSelectField;
  old?: boolean;
};

const useFilterBooleanSelect = ({
  onSearchRequest,
  field,
  old = false,
}: PropsType) => {
  const booleanOptions = OPTIONS_MAP[field];

  const handleSelectBooleanType = useCallback(
    (option: ValueType<IClientSelectOptionV2<boolean>, false>) => {
      const newOption = old ? option?.value : option;
      onSearchRequest(newOption, field);
    },
    [field, old, onSearchRequest]
  );

  return { handleSelectBooleanType, booleanOptions };
};

export default useFilterBooleanSelect;
