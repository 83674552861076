import classNames from 'clsx';
import TDCheckBox from 'components/tables/TDCheckBox/TDCheckBox';
import { FC, memo } from 'react';
import { formatDateTimeShort } from '../../../../../services/utils/dateHelper/dateHelper';
import { parseISO } from 'date-fns';
import { t } from 'services/utils/translation';
import TDSubtitle from 'components/tables/TDSubtitle/TDSubtitle';
import { extractBooleanToText } from 'services/utils/stringHelper/extractBooleanToText/extractBooleanToText';
import { IPersonV2 } from 'services/interfaces/IPersonV2';

const getNameText = extractBooleanToText(t('Отправил'), t('Принял'));

type PropsTypeTDDocumnetArchive = {
  id: string;
  isVisible: boolean;
  isChecked: boolean;
  date?: string;
  hint: string;
  sentToArchive?: boolean;
  recivedToArchive?: boolean;
  author?: IPersonV2;
};

const TDDocumnetArchive: FC<PropsTypeTDDocumnetArchive> = (props) => {
  const { id, isVisible, date, isChecked, hint, author, sentToArchive } = props;
  const clientDate = date && formatDateTimeShort(parseISO(date));

  return (
    <td
      className={classNames({
        'd-none': !isVisible,
      })}
    >
      <div className="d-flex-column">
        <div>
          <TDCheckBox id={id} hint={hint} isCheck={isChecked} />{' '}
        </div>
        {isChecked && (
          <div className="pt-2">
            {clientDate && (
              <div className="pt-1">
                <TDSubtitle>{t('Дата: ')}</TDSubtitle>
                <span>{clientDate}</span>
              </div>
            )}
            {author && (
              <div className="pt-1">
                <TDSubtitle>{getNameText(!!sentToArchive)}:</TDSubtitle>
                <div>{author?.full_name}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </td>
  );
};
export default memo(TDDocumnetArchive);
