import { useCallback } from 'react';
import onDownloadAll from '../utils/onDownloadAll';

const useDownoloadAll = () => {
  return useCallback(async (fileNames: string[]) => {
    onDownloadAll(fileNames);
  }, []);
};

export default useDownoloadAll;
