import classNames from 'clsx';
import styles from './index.module.scss';

import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import ColoredStatusLabel from 'components/misc/ColoredStatusLabel';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import HeaderWithSearch from 'components/tables/HeaderWithSearch';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import Pager from 'components/tables/Pager';
import { useColumnFilter } from 'hooks/useColumnFilter';
import { ReactComponent as CreateIcon } from 'img/icons/icon-file.svg';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as PrintIcon } from 'img/icons/icon-printer.svg';
import { ReactComponent as CopyIcon } from 'img/icons/icon-duplicate.svg';
import { ReactComponent as ArchiveIcon } from 'img/icons/icon-layers.svg';
import { ReactComponent as AddIcon } from 'img/icons/icon-plus.svg';

import React, { FC, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'services/utils/translation';
import { useReactToPrint } from 'react-to-print';
import LoaderFetch from '../../../../components/layouts/LoaderFetch/LoaderFetch';
import useClearFilters from 'hooks/useClearFilters';

import HeaderWithDynamicSelectPaginatedV2 from 'components/tables/HeaderWithDynamicSelectPaginatedV2/HeaderWithDynamicSelectPaginatedV2';
import { IUseGetList, useGetList } from '../../../../hooks/useGetList';
import HeaderWithDatePickerRangeV2 from 'components/tables/HeaderWithDatePickerRangeV2/HeaderWithDatePickerRangeV2';

import { getColorFromHEX } from '../../../../components/tables/misc/colorTr';
import SubHeaderButtonExport from 'components/tables/SubHeaderButtonExport/SubHeaderButtonExport';
import { IExternalRequestFilters } from '../../../../services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import {
  exportExternalRequestV2,
  getPaginatedExternalRequestApiV2,
} from '../../../../services/api/external-request/external-request/externalRequestApi';
import { getExternalRequestMarkedFromStore } from '../../../../store/externalRequest/selectors';
import { IExternalRequestForList } from '../../../../services/api/external-request/external-request/IExternalRequest';
import { setMarkedExternalRequest } from '../../../../store/externalRequest/actions';
import { getForSelectResponsibleDepartmentApiV2 } from '../../../../services/api/external-request/responsible-department/responsibleDepartmentApi';
import { getAvailableStatusesV2Old } from '../../../../services/api/external-request/external-request-status/externalRequestStatusApiV2';
import ExternalRequestPrintTable from '../../ExternalRequestPrint/ExternalRequestPrintTable/ExternalRequestPrintTable';
import { usePostV3 } from 'hooks/usePostV3';
import ExternalRequestPrintDetail from '../../ExternalRequestPrint/ExternalRequestPrintDetail/ExternalRequestPrintDetail';
import HeaderWithSelectAdvanced from 'components/tables/HeaderWithSelectAdvanced/HeaderWithSelectAdvanced';
import useGetDataOld from '../../../../hooks/useGetDataOld';
import { IExternalRequestStatusOld } from '../../../../services/api/external-request/external-request/IExternalRequestStatusOld';
import { getForSelectWorkCategoryApiV2 } from '../../../../services/api/external-request/work-category/workCategoryApi';
import { createClientSelectOptionV2 } from '../../../../services/utils/selects/selects';
import { toExternalRequestForList } from './utils/toExternalRequestForList/toExternalRequestForList';
import { getForSelectCounterpartyAffiliateApiV2 } from '../../../../services/api/organizationsV2/counterparty/counterpartyApi';
import { initialExternalRequestFilters } from '../../../../store/externalRequest/initialExternalRequestFilters';
import { getForSelectEmployeeApiV2 } from '../../../../services/api/staff/employee/employeeApi';
import { doPrunedExternalRequestApiV2 } from '../../../../services/api/external-request/external-request/externalRequestApi';
import RefreshButton from 'components/tables/RefreshButton/RefreshButton';
import DoubleHeaderFilterContainer from '../../../../components/tables/DoubleHeaderFilterContainer/DoubleHeaderFilterContainer';
import useExternalRequestHandleSearch from './hooks/useExternalRequestHandleSearch';
import {
  COLUMN_LABELS_EXTERNAL_REQUEST,
  columnVisibilityInitialExternalRequest,
  externalRequestFilters,
} from './utils/columnLabelsExternalRequest';
import useDoubleFilterUtilitiesForExternalRequest from './hooks/useDoubleFilterUtilitiesForExternalRequest';
import useHandlePrintDetailExternalRequest from './hooks/useHandlePrintDetailExternalRequest';
import CellWithExternalRequestPk from './ui/CellWithExternalRequestPk/CellWithExternalRequestPk';
import { getDropDownEditTitle } from '../../../../services/utils/extractGetLeftValueIfTrue';
import TDSubtitle from 'components/tables/TDSubtitle/TDSubtitle';
import useGetExternalRequestNavigationHandlers from './hooks/useGetNavigationHandlers';
import { makeFirstLetterUppercase } from 'services/utils/stringHelper/stringHelper';
import {
  EVENT_TEXT,
  RESPONSIBLE_DEPARTMENT_TEXT,
  WORK_CATEGORY_TEXT,
} from 'services/localLocalization/servicesAndModels';
import { makeLink } from 'services/localPaths';
import { PATH_ORGANIZATIONS_ACCOUNTS_VIEW } from 'services/pathConstants';
import { Link } from 'react-router-dom';
import TDBillingAccount from 'components/tables/TDBillingAccount/TDBillingAccount';
import DropdownText from 'components/tables/DropdownText/DropdownText';
import { getForSelectBillingAccountApiV2 } from 'services/api/organizationsV2/billing-account/billingAccountApi';
import { getForSelectEventApi } from 'services/api/organizationsV2/event/eventApi';
import useFilterBooleanSelect, {
  BooleanSelectField,
} from 'hooks/useFilterIsPruned/useFilterBooleanSelect';
import THCustom from 'components/tables/THCustom/THCustom';
import {
  THCustomDate,
  THCustomSelect,
  THCustomUnruled,
} from '../../../../components/tables/THCustomWrapper/THCustomWrapper';
import useGetHintForDoubleFiltersExternalRequest from './hooks/useGetHintForDoubleFiltersExternalRequest';
import useComposeExternalRequestFilters, {
  useComposeExternalRequestFiltersForExport,
} from './hooks/useComposeExternalRequestFilters';
import useGetInitialExternalRequestFilters from './hooks/useGetInitialExternalRequestFilters';
import useSaveExternalRequestFilters from './hooks/useSaveExternalRequestFilters';
import {
  FilterType,
  getClearFilters,
} from 'services/utils/getClearTaskAndExternalRequestFilters/getClearTaskAndExternalRequestFilters';
import getPermanentFiltersValue from 'services/utils/getPermanentFiltersValue/getPermanentFiltersValue';
import MyFilters from 'components/tables/MyFilters/MyFilters';
import useGetKeyForMyFilter from '../../../../hooks/useGetKeyForMyFilter/useGetKeyForMyFilter';
import TableWrapper from 'components/tables/TableWrapper/TableWrapper';
import useSetAndGetExternalRequestAccessPolicy from './hooks/useSetExternalRequestAccessPolicy';

export interface IExternalRequestEntityPk {
  billingAccountPk?: number;
  eventPk?: number;
}

export const convertStatuses = (payload: {
  list_of_entities?: IExternalRequestStatusOld[];
}) => {
  if (!payload?.list_of_entities) {
    return [];
  }
  return payload.list_of_entities.map(
    ({ id, name }: IExternalRequestStatusOld) =>
      createClientSelectOptionV2(id, name)
  );
};

type ExternalRequestPagePropsType = {} & IExternalRequestEntityPk;

const ExternalRequestPage: FC<ExternalRequestPagePropsType> = (props) => {
  const { billingAccountPk, eventPk } = props;

  const dispatchRedux = useDispatch();

  const isViewAccountPage = !!billingAccountPk || !!eventPk;

  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(
      columnVisibilityInitialExternalRequest,

      'externalRequestColumnFilter'
    );

  // GET AND SET ACCESS POLICY

  const {
    employeeHaveAccessToRead,
    billingAccountHaveAccessToRead,
    eventHaveAccessToRead,
    workCategoryHaveAccessToRead,
    responsibleDepartmentHaveAccessToRead,
    esxternalRequest: {
      haveAccessToArchive,
      haveAccessToUpdate,
      haveAccessToCreate,
      haveAccessToExport,
    },
  } = useSetAndGetExternalRequestAccessPolicy();

  const doPrunedExternalRequest = usePostV3({
    fetchApi: doPrunedExternalRequestApiV2,
    viewClientTextToast: true,
    successMessage: 'Заявка перенесена в архив',
  });

  const { data: initialStatuses } = useGetDataOld({
    getDataApi: getAvailableStatusesV2Old,
    convertData: convertStatuses,
    initialData: [],
  });

  const saveFilters = useSaveExternalRequestFilters({
    billingAccountPk,
    eventPk,
  });

  const initialParams = useGetInitialExternalRequestFilters({
    billingAccountPk,
    eventPk,
  });

  const onFinally = () => setClearingFiltersSpinner(false);

  const composeParamsGetExternalRequests = useComposeExternalRequestFilters();
  const composeParamsGetExternalRequestsForExport =
    useComposeExternalRequestFiltersForExport();

  const {
    data: externalRequests,
    isLoading,
    total,
    onSearchRequest,
    onSortClick,
    params,
    setStart,
    setLength,
    setParams,
    refreshListData,
    refreshAndReturnToStartPosition,
  }: IUseGetList<
    IExternalRequestForList[],
    IExternalRequestFilters
  > = useGetList({
    getDataApi: getPaginatedExternalRequestApiV2,
    initialParams,
    convertData: toExternalRequestForList,
    saveFilters,
    onFinally,
    convertedParameters: composeParamsGetExternalRequests,
  });

  const {
    external_request_pk: filterExternalRequestPk,
    external_request_author_dk_array: authorDkArray,
    external_request_address_of_incident: address,
    external_request_billing_account_fk_array: billingAccountFkArrayFilter,
    external_request_event_fk_array: eventFkArrayFilter,
    external_request_status_fk_array: filterStatus,
    external_request_implementer_dk_array: implementerDkArray,
    external_request_description: description,
    external_request_responsible_department_fk_array: departmentFkArray,
    external_request_executive_implementer_dk: executiveImplementerDk,
    external_request_date_supposed_duedate_upper_bound: duedateUpper,
    external_request_date_supposed_duedate_lower_bound: duedateLower,
    external_request_datetime_edited_upper_bound: datetimeEditedUpper,
    external_request_datetime_edited_lower_bound: datetimeEditedLower,
    external_request_datetime_added_upper_bound: datetimeAddedUpper,
    external_request_datetime_added_lower_bound: datetimeAddedLower,
    external_request_work_category_fk_array: workCategoryFkArray,
    external_request_affiliate_fk_array: affiliateFkArray,
    external_request_is_pruned: isPrunedFilter,
    external_request_supervisor_dk: filterSupervisor,
    external_request_external_code_number: filterCodeNumber,
    isActivePkFilter,
    isActiveBillingAccountFilter,
    isActiveImplementerDkArrayFilter,
    isActiveWorkCategoryFilter,
    skip,
    length,
  } = params;

  const archiveExternalRequestHandler = useCallback(
    async (pk: number) => {
      await doPrunedExternalRequest({ pk });
      refreshListData();
    },
    [doPrunedExternalRequest, refreshListData]
  );

  const {
    handleSearchExternalRequestPk,
    handleSearchExternalRequestCodeNumber,
    handleSelectAuthorDkArray,
    handleSearchAddress,
    handleSelectBillingAccount,
    handleSelectEvent,
    handleSearchDescription,
    handleSelectWorkCategoryArray,
    handleSelectDepartmentArray,
    handleSearchCounterpartyAffiliate,
    handleSelectImplementersArray,
    handleSelectExecutiveImplementer,
    handleSelectSupervisor,
    handleSelectStatus,
  } = useExternalRequestHandleSearch(onSearchRequest);

  const clearExternalRequestFilters = getClearFilters(
    FilterType.externalRequest,
    {
      billingAccountPk,
      eventPk,
    }
  );

  const permanentValue = getPermanentFiltersValue(FilterType.externalRequest, {
    billingAccountPk,
    eventPk,
  });

  //FILTERS_UTILS
  
  const { clearFilters, isDisableClearButton, setClearingFiltersSpinner } =
    useClearFilters(
      initialExternalRequestFilters,
      params,
      clearExternalRequestFilters,
      setParams,
      permanentValue
    );

  const { handleSelectBooleanType, booleanOptions } = useFilterBooleanSelect({
    onSearchRequest,
    field: BooleanSelectField.externalRequest,
  });

  // DOUBLE FILTERS UTILS

  const {
    hintForPkFilter,
    hintForCodeNumber,
    hintForExecutiveImplementerFilter,
    hintForImplementersFilter,
    hintForBillingAccountFilter,
    hintForEventFilter,
    hintForResponsibleDepartmentArrayFilter,
    hintForWorkCategoryFilter,
  } = useGetHintForDoubleFiltersExternalRequest(params);

  const {
    callSetIsActivePkFilter,
    setIsActiveBillingAccountFilter,
    callSetIsActiveImplementersFilter,
    callSetIsActiveWorkCategoryFilter,
  } = useDoubleFilterUtilitiesForExternalRequest({ eventPk, billingAccountPk });

  const isDisabledDoubleFilterEventOrBillingAccount =
    !!billingAccountPk || !!eventPk;

  //NAVIGATION

  const {
    nextWindowCreateChildrenTask,
    nextWindowChildrenExternalRequest,
    nextWindowExternalRequestDuplicatePage,
    nextWindowExternalRequestEditPage,
    handleAddExternalRequest,
  } = useGetExternalRequestNavigationHandlers({
    billingAccountId: billingAccountPk,
    eventPk,
  });

  //PRINT TABLE

  const externalRequestTableRef = useRef<HTMLTableElement>(null);
  const handlePrint = useReactToPrint({
    content: () => externalRequestTableRef.current,
    documentTitle: 'Заявки',
    copyStyles: true,
  });

  //PRINT DETAIL

  const externalRequestDetailsPrintRef = useRef<HTMLDivElement>(null);

  const { handlePrintDetails, printData } = useHandlePrintDetailExternalRequest(
    externalRequestDetailsPrintRef
  );

  // UTILS

  const keyMyFilter = useGetKeyForMyFilter({
    billingAccountPk,
    eventPk,
    filterType: FilterType.externalRequest,
  });

  const marked = useSelector(getExternalRequestMarkedFromStore);

  return (
    <MainLayout isInWrapper={isViewAccountPage}>
      <Subheader>
        <div className=" d-flex mr-5">
          <SubheaderButton>
            <MyFilters
              setFilters={setParams}
              currentFilter={params}
              keyMyFilter={keyMyFilter}
              saveFilters={saveFilters}
            />
          </SubheaderButton>
          <SubheaderButton
            onClick={clearFilters}
            bordered={true}
            disabled={isDisableClearButton}
          >
            <span>{t('Сбросить фильтры')}</span>
          </SubheaderButton>
          <RefreshButton
            toggleRefresh={refreshAndReturnToStartPosition}
            isLoading={isLoading}
          />
          <SubHeaderButtonExport
            params={params}
            getExportLinkApi={exportExternalRequestV2}
            disabled={isLoading}
            convertParams={composeParamsGetExternalRequestsForExport}
            haveAccessToExport={haveAccessToExport}
          />
          <SubheaderButton
            onClick={handlePrint}
            bordered={false}
            hintText={t('Распечатать')}
            hintTarget={t('handlePrint')}
          >
            <PrintIcon />
          </SubheaderButton>
        </div>
        {haveAccessToCreate && (
          <SubheaderButton onClick={handleAddExternalRequest} bordered={false}>
            <CreateIcon className="mr-2 text-white" /> {t('Добавить заявку')}
          </SubheaderButton>
        )}
      </Subheader>
      <TableWrapper>
        <thead>
          <tr>
            <THCustom
              className={styles.thPkAndCodeNumber}
              isVisible={visibility.pkAndCodeNumber}
            >
              <DoubleHeaderFilterContainer
                isFirstIsVisibleProp={isActivePkFilter}
                saveIsActive={callSetIsActivePkFilter}
                componentSettings={{
                  first: {
                    value: filterExternalRequestPk,
                    hint: hintForPkFilter,
                    name: 'filterPk',
                  },
                  second: {
                    value: filterCodeNumber,
                    hint: hintForCodeNumber,
                    name: 'filterCodeNumber',
                  },
                }}
              >
                <HeaderWithSearchAndSort
                  field="external_request_pk"
                  title={COLUMN_LABELS_EXTERNAL_REQUEST.PK}
                  onSort={onSortClick}
                  onSearch={handleSearchExternalRequestPk}
                  defaultValue={
                    filterExternalRequestPk ? filterExternalRequestPk + '' : ''
                  }
                  isOnlyNumbers
                />
                <HeaderWithSearch
                  title={COLUMN_LABELS_EXTERNAL_REQUEST.CODE_NUMBER}
                  onSearch={handleSearchExternalRequestCodeNumber}
                  defaultValue={filterCodeNumber ? filterCodeNumber + '' : ''}
                />
              </DoubleHeaderFilterContainer>
            </THCustom>
            <THCustomDate
              className={styles.thCrateDate}
              isVisible={visibility.createDate}
            >
              <HeaderWithDatePickerRangeV2
                title={COLUMN_LABELS_EXTERNAL_REQUEST.CREATE_DATE}
                propsOnSearch={{
                  onSearchRequest,
                  keyDate: [
                    'external_request_datetime_added_lower_bound',
                    'external_request_datetime_added_upper_bound',
                  ],
                }}
                startDate={
                  datetimeAddedLower ? new Date(datetimeAddedLower) : null
                }
                endDate={
                  datetimeAddedUpper ? new Date(datetimeAddedUpper) : null
                }
                field="external_request_datetime_added"
                onSort={onSortClick}
                hasTime
              />
            </THCustomDate>
            <THCustomDate
              className={styles.thEditedDate}
              isVisible={visibility.editedDate}
            >
              <HeaderWithDatePickerRangeV2
                title={COLUMN_LABELS_EXTERNAL_REQUEST.EDITED_DATE}
                propsOnSearch={{
                  onSearchRequest,
                  keyDate: [
                    'external_request_datetime_edited_lower_bound',
                    'external_request_datetime_edited_upper_bound',
                  ],
                }}
                startDate={
                  datetimeEditedLower ? new Date(datetimeEditedLower) : null
                }
                endDate={
                  datetimeEditedUpper ? new Date(datetimeEditedUpper) : null
                }
                field="external_request_datetime_edited"
                onSort={onSortClick}
                hasTime
              />
            </THCustomDate>
            <THCustomDate
              className={styles.thAuthor}
              isVisible={visibility.author}
            >
              <HeaderWithDynamicSelectPaginatedV2
                selectHandler={getForSelectEmployeeApiV2}
                id="external_request_author_dk_array"
                title={COLUMN_LABELS_EXTERNAL_REQUEST.AUTHOR}
                options={authorDkArray}
                isMulti={true}
                onChange={handleSelectAuthorDkArray}
                haveReadPermission={employeeHaveAccessToRead}
              />
            </THCustomDate>
            <THCustom
              className={styles.thAddress}
              isVisible={visibility.address}
            >
              <HeaderWithSearch
                title={COLUMN_LABELS_EXTERNAL_REQUEST.ADDRESS}
                onSearch={handleSearchAddress}
                defaultValue={address}
              />
            </THCustom>
            {isDisabledDoubleFilterEventOrBillingAccount && (
              <THCustomSelect
                className={styles.thBillingAccountAndEvent}
                isVisible={visibility.billingAccountAndEvent}
              >
                <DoubleHeaderFilterContainer
                  isFirstIsVisibleProp={isActiveBillingAccountFilter}
                  saveIsActive={setIsActiveBillingAccountFilter}
                  componentSettings={{
                    first: {
                      value: billingAccountFkArrayFilter,
                      hint: hintForBillingAccountFilter,
                      name: 'filterBillingAccount',
                    },
                    second: {
                      value: eventFkArrayFilter,
                      hint: hintForEventFilter,
                      name: 'filterEvent',
                    },
                  }}
                >
                  <HeaderWithDynamicSelectPaginatedV2
                    selectHandler={getForSelectBillingAccountApiV2}
                    id="external_request_billing_account_fk_array"
                    title={COLUMN_LABELS_EXTERNAL_REQUEST.BILLING_ACCOUNT}
                    options={billingAccountFkArrayFilter}
                    isMulti={true}
                    onChange={handleSelectBillingAccount}
                    haveReadPermission={billingAccountHaveAccessToRead}
                  />
                  <HeaderWithDynamicSelectPaginatedV2
                    selectHandler={getForSelectEventApi}
                    id="external_request_event_fk_array"
                    title={COLUMN_LABELS_EXTERNAL_REQUEST.EVENT}
                    options={eventFkArrayFilter}
                    isMulti={true}
                    onChange={handleSelectEvent}
                    haveReadPermission={eventHaveAccessToRead}
                  />
                </DoubleHeaderFilterContainer>
              </THCustomSelect>
            )}
            <THCustom className={styles.thDescription}>
              <HeaderWithSearch
                title={COLUMN_LABELS_EXTERNAL_REQUEST.DESCRIPTION}
                onSearch={handleSearchDescription}
                defaultValue={description}
              />
            </THCustom>

            <THCustomSelect
              className={styles.thWorkCategoryAndResponsibleDepartment}
              isVisible={visibility.workCategoryAndResponsibleDepartment}
            >
              <DoubleHeaderFilterContainer
                isFirstIsVisibleProp={isActiveWorkCategoryFilter}
                saveIsActive={callSetIsActiveWorkCategoryFilter}
                componentSettings={{
                  first: {
                    value: workCategoryFkArray,
                    hint: hintForWorkCategoryFilter,
                    name: 'filterWorkCategory',
                  },
                  second: {
                    value: departmentFkArray,
                    hint: hintForResponsibleDepartmentArrayFilter,
                    name: 'filterResponsibleDepartment',
                  },
                }}
              >
                <HeaderWithDynamicSelectPaginatedV2
                  id="external_request_work_category_fk_array"
                  selectHandler={getForSelectWorkCategoryApiV2}
                  title={COLUMN_LABELS_EXTERNAL_REQUEST.WORK_CATEGORY}
                  isMulti={true}
                  options={workCategoryFkArray}
                  onChange={handleSelectWorkCategoryArray}
                  haveReadPermission={workCategoryHaveAccessToRead}
                />
                <HeaderWithDynamicSelectPaginatedV2
                  id="external_request_responsible_department_fk_array"
                  selectHandler={getForSelectResponsibleDepartmentApiV2}
                  title={COLUMN_LABELS_EXTERNAL_REQUEST.RESPONSIBLE_DEPARTMENT}
                  isMulti={true}
                  options={departmentFkArray}
                  onChange={handleSelectDepartmentArray}
                  haveReadPermission={responsibleDepartmentHaveAccessToRead}
                />
              </DoubleHeaderFilterContainer>
            </THCustomSelect>
            <THCustomSelect
              className={styles.thAffiliate}
              isVisible={visibility.affiliate}
            >
              <HeaderWithDynamicSelectPaginatedV2
                selectHandler={getForSelectCounterpartyAffiliateApiV2}
                id="external_request_affiliate_fk_array"
                title={COLUMN_LABELS_EXTERNAL_REQUEST.AFFILIATE}
                options={affiliateFkArray}
                isMulti={true}
                onChange={handleSearchCounterpartyAffiliate}
              />
            </THCustomSelect>
            <THCustomSelect
              className={styles.thExecutiveImplementersAndImplementers}
              isVisible={visibility.executiveImplementersAndImplementers}
            >
              <DoubleHeaderFilterContainer
                isFirstIsVisibleProp={isActiveImplementerDkArrayFilter}
                saveIsActive={callSetIsActiveImplementersFilter}
                componentSettings={{
                  first: {
                    value: implementerDkArray,
                    hint: hintForImplementersFilter,
                    name: 'implementers',
                  },
                  second: {
                    value: executiveImplementerDk,
                    hint: hintForExecutiveImplementerFilter,
                    name: 'executiveImplementer',
                  },
                }}
              >
                <HeaderWithDynamicSelectPaginatedV2
                  selectHandler={getForSelectEmployeeApiV2}
                  id="external_request_implementer_dk_array"
                  title={COLUMN_LABELS_EXTERNAL_REQUEST.IMPLEMENTERS}
                  options={implementerDkArray}
                  onChange={handleSelectImplementersArray}
                  isMulti={true}
                  haveReadPermission={employeeHaveAccessToRead}
                />
                <HeaderWithDynamicSelectPaginatedV2
                  selectHandler={getForSelectEmployeeApiV2}
                  id="external_request_executive_implementer_dk"
                  title={COLUMN_LABELS_EXTERNAL_REQUEST.EXECUTIVE_IMPLEMENTER}
                  options={executiveImplementerDk}
                  onChange={handleSelectExecutiveImplementer}
                  haveReadPermission={employeeHaveAccessToRead}
                />
              </DoubleHeaderFilterContainer>
            </THCustomSelect>
            <THCustomSelect
              className={styles.thSupervisor}
              isVisible={visibility.supervisor}
            >
              <HeaderWithDynamicSelectPaginatedV2
                id="external_request_supervisor_dk"
                selectHandler={getForSelectEmployeeApiV2}
                title={COLUMN_LABELS_EXTERNAL_REQUEST.SUPERVISOR}
                options={filterSupervisor}
                onChange={handleSelectSupervisor}
                haveReadPermission={employeeHaveAccessToRead}
              />
            </THCustomSelect>
            <THCustomSelect
              className={styles.thDuedate}
              isVisible={visibility.duedate}
            >
              <HeaderWithDatePickerRangeV2
                title={COLUMN_LABELS_EXTERNAL_REQUEST.DATE_DUEDATE}
                propsOnSearch={{
                  onSearchRequest,
                  keyDate: [
                    'external_request_date_supposed_duedate_lower_bound',
                    'external_request_date_supposed_duedate_upper_bound',
                  ],
                }}
                startDate={duedateLower ? new Date(duedateLower) : null}
                endDate={duedateUpper ? new Date(duedateUpper) : null}
                field="external_request_date_supposed_duedate"
                onSort={onSortClick}
              />
            </THCustomSelect>
            <THCustomSelect
              className={styles.thStatus}
              isVisible={visibility.status}
            >
              <HeaderWithSelectAdvanced
                label={COLUMN_LABELS_EXTERNAL_REQUEST.STATUS}
                options={initialStatuses}
                onChange={handleSelectStatus}
                defaultValue={filterStatus}
                isMulti={true}
              />
            </THCustomSelect>
            <THCustomSelect
              className={styles.thIsPruned}
              isVisible={visibility.is_pruned}
            >
              <HeaderWithSelectAdvanced
                label={COLUMN_LABELS_EXTERNAL_REQUEST.ARCHIVE}
                options={booleanOptions}
                onChange={handleSelectBooleanType}
                defaultValue={isPrunedFilter}
              />
            </THCustomSelect>
            <THCustomUnruled
              className={styles.thParentTask}
              isVisible={visibility.parentTask}
            >
              {COLUMN_LABELS_EXTERNAL_REQUEST.PARENT_TASK}
            </THCustomUnruled>
            <THCustomUnruled
              className={styles.thParentExternalRequest}
              isVisible={visibility.parentExternalRequest}
            >
              {COLUMN_LABELS_EXTERNAL_REQUEST.PARENT_EXTERNAL_REQUEST}
            </THCustomUnruled>
            <th scope="col" className={classNames('actions p-0')}>
              <ColumnFilter onApply={applyVisibilityChanges}>
                {externalRequestFilters.map(({ name, label }) => (
                  <ColumnFilterItem
                    key={name}
                    name={name}
                    label={label}
                    defaultChecked={visibility[name]}
                    onChange={onFilterItemToggle}
                  />
                ))}
              </ColumnFilter>
            </th>
          </tr>
        </thead>
        {!isLoading && (
          <tbody>
            {externalRequests &&
              externalRequests.map(
                (externalRequest: IExternalRequestForList) => {
                  const {
                    pk,
                    createDate,
                    editedDate,
                    external_request_author: author,
                    external_request_address_of_incident: address,
                    external_request_description: description,
                    affiliateLink,
                    external_request_responsible_department: department,
                    // external_request_supervisor: supervisor,
                    external_request_work_category: workCategory,
                    implementersWithPositions,
                    external_request_executive_implementer:
                      executiveImplementer,
                    duedate,
                    external_request_status: status,
                    parentAndChildExternalRequestLinks,
                    parentAndChildTaskLinks,
                    isPrunedText,
                    external_request_is_pruned: isPruned,
                    external_request_supervisor: supervisor,
                    external_request_external_code_number: codeNumber,
                    billingAccount,
                    event,
                  } = externalRequest;
                  return (
                    <tr
                      key={pk}
                      className={classNames(
                        `table-${getColorFromHEX(status?.color)}`,
                        {
                          [styles.trActive]:
                            marked.includes(pk) && status?.color === '#FFFFFF',

                          [styles.trActiveColor]: marked.includes(pk),
                          [styles.trArchive]: isPruned,
                        }
                      )}
                      onClick={(event: any) => {
                        if (event.target.tagName === 'TD') {
                          dispatchRedux(setMarkedExternalRequest(pk));
                        }
                      }}
                    >
                      <td
                        className={classNames({
                          'd-none': !visibility.pkAndCodeNumber,
                        })}
                        style={{ width: '4%' }}
                      >
                        <CellWithExternalRequestPk
                          pk={pk}
                          codeNumber={codeNumber}
                        />
                      </td>
                      <td
                        style={{ width: '7%' }}
                        className={classNames({
                          'd-none': !visibility.createDate,
                        })}
                      >
                        <span>{createDate}</span>
                      </td>
                      <td
                        style={{ width: '7%' }}
                        className={classNames({
                          'd-none': !visibility.editedDate,
                        })}
                      >
                        <span>{editedDate}</span>
                      </td>
                      <td
                        style={{ width: '7%' }}
                        className={classNames({
                          'd-none': !visibility.author,
                        })}
                      >
                        <span> {author?.full_name}</span>
                      </td>
                      <td
                        style={{ width: '9%' }}
                        className={classNames({
                          'd-none': !visibility.address,
                        })}
                      >
                        <span>{address}</span>
                      </td>
                      {isDisabledDoubleFilterEventOrBillingAccount && (
                        <td
                          style={{ width: '9%' }}
                          className={classNames({
                            'd-none': !visibility.billingAccountAndEvent,
                          })}
                        >
                          {billingAccount ? (
                            <TDBillingAccount billingAccount={billingAccount} />
                          ) : (
                            <>
                              {!!event && (
                                <>
                                  <TDSubtitle>
                                    {makeFirstLetterUppercase(EVENT_TEXT)}:
                                  </TDSubtitle>
                                  <br />

                                  <Link
                                    to={makeLink(
                                      PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
                                      event.event_pk
                                    )}
                                  >
                                    {event?.event_name}
                                  </Link>
                                </>
                              )}
                            </>
                          )}
                        </td>
                      )}
                      <td>
                        {description && <DropdownText str={description} />}
                      </td>

                      <td
                        className={classNames({
                          'd-none':
                            !visibility.workCategoryAndResponsibleDepartment,
                        })}
                      >
                        <div className={'d-flex-column'}>
                          {workCategory && (
                            <div className={'d-flex-column pb-2 '}>
                              <TDSubtitle>
                                {makeFirstLetterUppercase(WORK_CATEGORY_TEXT)}:
                              </TDSubtitle>
                              <div>{workCategory?.work_category_rendition}</div>
                            </div>
                          )}
                          {department && (
                            <div className="d-flex-column">
                              <TDSubtitle>
                                {makeFirstLetterUppercase(
                                  RESPONSIBLE_DEPARTMENT_TEXT
                                )}
                                :
                              </TDSubtitle>
                              <div>
                                {department?.responsible_department_rendition}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.affiliate,
                        })}
                      >
                        {affiliateLink}
                      </td>
                      <td
                        style={{ width: '9%' }}
                        className={classNames({
                          'd-none':
                            !visibility.executiveImplementersAndImplementers,
                        })}
                      >
                        {executiveImplementer && (
                          <>
                            <div>
                              <TDSubtitle>{t('Ответственный')}: </TDSubtitle>
                              <span>{executiveImplementer?.full_name}</span>
                            </div>
                            <br />
                          </>
                        )}
                        {implementersWithPositions && (
                          <TDSubtitle>{t('Исполнители')}: </TDSubtitle>
                        )}
                        {implementersWithPositions?.map(
                          (implementer: string) => {
                            return <div key={implementer}>{implementer}</div>;
                          }
                        )}
                      </td>
                      <td
                        style={{ width: '9%' }}
                        className={classNames({
                          'd-none': !visibility.supervisor,
                        })}
                      >
                        <span>{supervisor?.full_name}</span>
                      </td>
                      <td
                        style={{ width: '7%' }}
                        className={classNames({
                          'd-none': !visibility.duedate,
                        })}
                      >
                        <span>{duedate}</span>
                      </td>
                      <td
                        style={{ width: '9%' }}
                        className={classNames({
                          'd-none': !visibility.status,
                        })}
                      >
                        {status && (
                          <ColoredStatusLabel color={status.color}>
                            {status.name}
                          </ColoredStatusLabel>
                        )}
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.is_pruned,
                        })}
                      >
                        {isPrunedText}
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.parentTask,
                        })}
                      >
                        {parentAndChildTaskLinks}
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.parentExternalRequest,
                        })}
                      >
                        {parentAndChildExternalRequestLinks}
                      </td>
                      <td align="center" style={{ width: '10px' }}>
                        <ActionsDropdown>
                          <ActionsDropdownItem
                            label={getDropDownEditTitle(
                              !isPruned && haveAccessToUpdate
                            )}
                            onClick={nextWindowExternalRequestEditPage.bind(
                              null,
                              pk
                            )}
                            icon={<EditIcon className="text-primary" />}
                          />
                          <ActionsDropdownItem
                            label={t('Копировать')}
                            onClick={nextWindowExternalRequestDuplicatePage.bind(
                              null,
                              pk
                            )}
                            icon={
                              <CopyIcon
                                className="text-primary"
                                style={{ width: '17px', height: '17px' }}
                              />
                            }
                          />

                          <ActionsDropdownItem
                            label={t('Распечатать')}
                            onClick={() => handlePrintDetails(pk)}
                            icon={
                              <PrintIcon
                                style={{ width: '18px', height: '18px' }}
                                className="text-primary"
                              />
                            }
                          />
                          <ActionsDropdownItem
                            label={t('Создать подзаявку')}
                            onClick={nextWindowChildrenExternalRequest.bind(
                              null,
                              pk
                            )}
                            icon={<AddIcon className="text-primary" />}
                          />
                          <ActionsDropdownItem
                            label={t('Создать подзадачу')}
                            onClick={nextWindowCreateChildrenTask.bind(
                              null,
                              pk
                            )}
                            icon={<AddIcon className="text-primary" />}
                          />
                          {!isPruned && haveAccessToArchive && (
                            <ActionsDropdownItem
                              label={t('В архив')}
                              onClick={archiveExternalRequestHandler.bind(
                                null,
                                pk
                              )}
                              icon={<ArchiveIcon className="text-primary" />}
                            />
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        )}
      </TableWrapper>
      {isLoading && <LoaderFetch />}
      {!isLoading && externalRequests?.length > 0 && (
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
          saveFilters={saveFilters}
        />
      )}
      <div
        ref={externalRequestDetailsPrintRef}
        style={{ marginTop: '80px' }}
        className={styles['print-source']}
      >
        <ExternalRequestPrintDetail printData={printData} />
      </div>
      <ExternalRequestPrintTable
        visibility={visibility}
        ref={externalRequestTableRef}
        className={classNames(styles['print-source'])}
        externalRequests={externalRequests}
        columnLabels={COLUMN_LABELS_EXTERNAL_REQUEST}
      />
    </MainLayout>
  );
};

//ExternalRequestPage.whyDidYouRender = true;

export default ExternalRequestPage;
