import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import React, { FC, useState } from 'react';
import useRead from '../../../../hooks/useRead';
import { readTaskApiV2 } from 'services/api/tasks/tasks/tasksApiV2';
import IKeyValueT from 'services/api/interfacesApi/IKeyValueT';
import {
  isLegalStatusCompany,
  toTitleBillingAccount,
} from '../../../../services/utils/bilingAccountUtils/billingAccountUtils';
import {
  ACCOUNT_LEGAL_STATUS_COMPANY,
  ACCOUNT_LEGAL_STATUS_INDIVIDUAL,
} from 'config';
import convertToServerPhoneNumber from 'services/utils/stringHelper/convertToServerPnoneNumber/convertToServerPnoneNumber';
import { IGetTaskPayload } from 'services/api/tasks/tasks/IGetTaskPayload';
import { useSelector } from 'react-redux';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import ExternalRequest from '../../../../models/ExternalRequest';
import { IEmployee } from '../../../../services/api/staff/employee/IEmployee';
import { getCurrentEmployeeFromStore } from '../../../../store/general/selectors';
import ExternalRequestHeader from 'components/external-request/ExternalRequestHeader/ExternalRequestHeader';
import { createClientSelectOptionV2 } from '../../../../services/utils/selects/selects';
import useFirstExternalRequestStatus from '../hooks/useFirstExternalRequestStatus';
import ExternalRequestForm, {
  IFormValuesExternalRequest,
} from '../../../../components/external-request/ExternalRequestForm/ExternalRequestForm/ExternalRequestForm';
import useGetIdFromPath from 'hooks/useGetIdFromPath/useGetIdFromPath';
import {
  EXTERNAL_REQUEST_NO_PERFORMERS,
  EXTERNAL_REQUEST_SET_PERFORMERS,
} from '../ExternalRequestEditPage/utils/consts';

type OrderEditPagePropTypes = {
  id?: string;
};

export interface IFormValuesExternalRequestFromTask
  extends IFormValuesExternalRequest {
  external_request_parent_internal_task_fk: number;
  legalType: string;
  external_request_reporter_phone_number?: string;
  executiveImplementer: IClientSelectOptionV2<number>;
  external_request_description: string;
  performerType: string;
}

const extractConvertTaskToInitialValues =
  (currentUser: IEmployee, firstStatus?: IClientSelectOptionV2) =>
  (task: IGetTaskPayload): IFormValuesExternalRequestFromTask => {
    const {
      internal_task: {
        internal_task_pk: taskPk,
        internal_task_billing_account: billingAccount,
        internal_task_billing_account: {
          billing_account_pk: billingAccountPk,
          billing_account_counterparty: billingAccountCounterparty,
        } = {},
        internal_task_reporter_phone_number: phoneNumber,
        internal_task_supervisor: { id: supervisorId, full_name: fullName },
        internal_task_description: description,
        internal_task_supervisor_dk: supervisorDk,
      },
    } = task;

    const valuesFromTask: IFormValuesExternalRequestFromTask = {
      external_request_parent_internal_task_fk: taskPk,
      legalType:
        billingAccountCounterparty &&
        isLegalStatusCompany(billingAccountCounterparty)
          ? ACCOUNT_LEGAL_STATUS_COMPANY
          : ACCOUNT_LEGAL_STATUS_INDIVIDUAL,
      external_request_reporter_phone_number: convertToServerPhoneNumber(
        phoneNumber + ''
      ),
      executiveImplementer: createClientSelectOptionV2(supervisorId, fullName),
      billingAccount:
        billingAccount && billingAccountPk
          ? createClientSelectOptionV2(
              billingAccountPk,
              toTitleBillingAccount(billingAccount)
            )
          : undefined,
      external_request_description: description,
      performerType: supervisorDk
        ? EXTERNAL_REQUEST_SET_PERFORMERS
        : EXTERNAL_REQUEST_NO_PERFORMERS,
      status: firstStatus,
    };
    return {
      ...new ExternalRequest(undefined, currentUser),
      ...valuesFromTask,
    };
  };

const ExternalRequestFromTask: FC<OrderEditPagePropTypes> = () => {
  const pk = Number(useGetIdFromPath());

  const firstStatus = useFirstExternalRequestStatus();
  const [initialParams] = useState<IKeyValueT<{ pk: number }>>({ pk });
  const user = useSelector(getCurrentEmployeeFromStore);
  const convertData = extractConvertTaskToInitialValues(user, firstStatus);
  const { data: initialValuesFromTask } = useRead({
    getDataApi: readTaskApiV2,
    initialData: null,
    params: initialParams,
    condition: !!pk,
    convertData,
  });
  return (
    <MainLayout>
      <Subheader />
      <ExternalRequestHeader isCreateFromTask haveAccessToCreate={true} />
      <ExternalRequestForm initialValuesFromTask={initialValuesFromTask} />
    </MainLayout>
  );
};
export default ExternalRequestFromTask;
