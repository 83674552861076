import { FC, ChangeEvent, useState, memo } from 'react';
import Counterparty from 'models/Counterparty';
import { usePostV3 } from '../../../../hooks/usePostV3';
import { createCounterpartyApiV2 } from 'services/api/organizationsV2/counterparty/counterpartyApi';
import {
  ICreateCounterparty,
  IUpdateCounterparty,
} from '../../../../services/api/organizationsV2/counterparty/ICreateCounterparty';
import { t } from '../../../../services/utils/translation';
import { updateCounterpartyApiV2 } from '../../../../services/api/organizationsV2/counterparty/counterpartyApi';
import { Formik } from 'formik';
import { Form, FormGroup, Button } from 'reactstrap';
import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import RadiosField from 'components/controls/RadiosField';
import {
  ACCOUNT_LEGAL_STATUS_COMPANY,
  ACCOUNT_LEGAL_STATUS_INDIVIDUAL,
} from '../../../../config';
import classNames from 'clsx';
import styles from './index.module.scss';
import DadataBaseField from 'components/controls/DaDataBaseField/DaDataBaseField';
import { DaDataAddressSuggestion, DaDataPartySuggestion } from 'react-dadata';
import { TypeSuggestion } from '../../../controls/DaDataBaseField/DaDataBaseField';
import PhoneField from 'components/controls/PhoneField/PhoneField';
import convertToServerPhoneNumber from 'services/utils/stringHelper/convertToServerPnoneNumber/convertToServerPnoneNumber';
import Checkbox from 'components/controls/Checkbox';
import deleteUnnecessaryParameters from '../../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';

type PropsType = {
  successHandler: () => void;
  pk?: number;
  initialValue: Counterparty;
  isLoading: boolean;
  isViewOnly: boolean;
};

const convertParamsForCreate = (values: Counterparty): ICreateCounterparty => {
  const newObject = deleteUnnecessaryParameters(values, ['isNew']);
  return newObject as ICreateCounterparty;
};

const convertParamsForUpdate = (values: Counterparty): IUpdateCounterparty => {
  const newValues = deleteUnnecessaryParameters(values);
  return newValues as IUpdateCounterparty;
};

const handleSuggestionCompanyName = (
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  suggestion?: DaDataPartySuggestion
) => {
  if (suggestion) {
    const {
      data: {
        address: { unrestricted_value: counterparty_legal_address },
        inn: counterparty_inn,
        kpp: counterparty_company_kpp,
        ogrn: counterparty_company_ogrn,
        name: { short_with_opf: companyName },
      },
      data,
      unrestricted_value,
    } = suggestion;
    const name = companyName ? companyName : data.name.full;
    setFieldValue('counterparty_legal_address', unrestricted_value);
    setFieldValue('counterparty_company_full_name', name);
    setFieldValue('counterparty_legal_address', counterparty_legal_address);
    setFieldValue('counterparty_inn', counterparty_inn);
    setFieldValue('counterparty_company_kpp', counterparty_company_kpp);
    setFieldValue('counterparty_company_ogrn', counterparty_company_ogrn);
  }
};

const CounterpartyForm: FC<PropsType> = (props) => {
  const { initialValue, isLoading, pk, successHandler, isViewOnly } = props;

  const [forceUpdate, setForceUpdate] = useState<number>(0);

  const isNew = !pk;

  const createCounterparty = usePostV3({
    fetchApi: createCounterpartyApiV2,
    successHandler,
    successMessage: t('Контрагент создан успешно.'),
    convertParams: convertParamsForCreate,
    viewClientTextToast: true,
  });

  const updateCounterparty = usePostV3({
    fetchApi: updateCounterpartyApiV2,
    successHandler,
    successMessage: t('Контрагент обновлен успешно.'),
    convertParams: convertParamsForUpdate,
    viewClientTextToast: true,
  });

  const renderDadata = (value?: string) => !!value || isNew;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={Counterparty.validationSchema()}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          setSubmitting(true);
          isNew &&
            (await createCounterparty(
              values as ICreateCounterparty,
              setErrors,
              resetForm
            ));

          !isNew &&
            values.pk &&
            (await updateCounterparty(
              values as IUpdateCounterparty,
              setErrors,
              resetForm
            ));

          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>{console.log(values, 'values')}
            {!isLoading && (
              <Form
                onSubmit={handleSubmit}
                noValidate={true}
                style={{ maxWidth: '100%' }}
              >
                <div
                  className={classNames(
                    'd-flex flex-column flex-md-row justify-content-md-between ml-4 mr-4 mr-md-auto pl-3'
                  )}
                >
                  <div
                    className={classNames(' w-100')}
                    style={{
                      maxWidth: '800px',
                    }}
                  >
                    {!isNew && (
                      <HiddenField
                        id="pk"
                        value={values.pk + ''}
                        hasError={errors.pk != null && touched.pk != null}
                      />
                    )}
                    <>
                      <fieldset className={classNames(styles.fieldsContainer)}>
                        <h4>{t('Статус контрагента')}</h4>
                        <RadiosField
                          name="counterparty_is_natural_person"
                          hasErrors={
                            errors.counterparty_is_natural_person != null
                          }
                          options={[
                            {
                              label: t('Физ. лицо'),
                              value: ACCOUNT_LEGAL_STATUS_INDIVIDUAL,
                            },
                            {
                              label: t('Юр. лицо'),
                              value: ACCOUNT_LEGAL_STATUS_COMPANY,
                            },
                          ]}
                          checkedValue={
                            values.counterparty_is_natural_person
                              ? ACCOUNT_LEGAL_STATUS_INDIVIDUAL
                              : ACCOUNT_LEGAL_STATUS_COMPANY
                          }
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(
                              'counterparty_is_natural_person',
                              event.target.value ===
                                ACCOUNT_LEGAL_STATUS_INDIVIDUAL
                            );
                          }}
                          inline={true}
                          disabled={isViewOnly || !isNew}
                        />
                        
                        <Checkbox
                          name="counterparty_is_affiliate"
                          label={t('Сделать партнером')}
                          checked={values.counterparty_is_affiliate}
                          hasError={errors.counterparty_is_affiliate != null}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(
                              'counterparty_is_affiliate',
                              !e.target.checked
                            );
                            handleChange(e);
                          }}
                          className="p-0"
                          disabled={isViewOnly}
                        />
                      </fieldset>
                      <fieldset className={classNames(styles.fieldsContainer)}>
                        <h4>{t('Основная информация')}</h4>

                        {values.counterparty_is_natural_person ? (
                          <>
                            <TextField
                              id="counterparty_individual_last_name"
                              type="text"
                              label={t('Фамилия')}
                              hasError={
                                errors.counterparty_individual_last_name != null
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              defaultValue={
                                values.counterparty_individual_last_name
                              }
                              value={values.counterparty_individual_last_name}
                              required={true}
                              disabled={isViewOnly}
                            />
                            <TextField
                              id="counterparty_individual_first_name"
                              type="text"
                              label={t('Имя')}
                              hasError={
                                errors.counterparty_individual_first_name !=
                                null
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              defaultValue={
                                values.counterparty_individual_first_name
                              }
                              value={values.counterparty_individual_first_name}
                              required={true}
                              disabled={isViewOnly}
                            />
                            <TextField
                              id="counterparty_individual_middle_name"
                              type="text"
                              label={t('Отчество')}
                              hasError={
                                errors.counterparty_individual_middle_name !=
                                  null &&
                                touched.counterparty_individual_middle_name !=
                                  null
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              defaultValue={
                                values.counterparty_individual_middle_name
                              }
                              value={values.counterparty_individual_middle_name}
                              disabled={isViewOnly}
                            />
                          </>
                        ) : (
                          <>
                            <DadataBaseField
                              inputProps={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                                required: true,
                              }}
                              typeSuggestion={TypeSuggestion.party}
                              id="counterparty_company_full_name"
                              type="text"
                              label={t('Полное наименование компании')}
                              hasError={
                                errors.counterparty_company_full_name != null &&
                                touched.counterparty_company_full_name != null
                              }
                              defaultValue={
                                values.counterparty_company_full_name
                              }
                              value={values.counterparty_company_full_name}
                              onChangeSelect={(
                                suggestion?: DaDataPartySuggestion
                              ) => {
                                handleSuggestionCompanyName(
                                  setFieldValue,
                                  suggestion
                                );
                                setForceUpdate((state: number) => ++state);
                              }}
                              disabled={isViewOnly}
                            />
                            <TextField
                              id="counterparty_company_short_name"
                              type="text"
                              label={t('Сокращённое наименование компании')}
                              hasError={
                                errors.counterparty_company_short_name !=
                                  null &&
                                touched.counterparty_company_short_name != null
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              defaultValue={
                                values.counterparty_company_short_name
                              }
                              value={values.counterparty_company_short_name}
                              disabled={isViewOnly}
                            />
                            <TextField
                              id="counterparty_company_kpp"
                              type="text"
                              label={t('КПП')}
                              hasError={
                                errors.counterparty_company_kpp != null &&
                                touched.counterparty_company_kpp != null
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              defaultValue={values.counterparty_company_kpp}
                              value={values.counterparty_company_kpp}
                              disabled={isViewOnly}
                            />
                            <TextField
                              id="counterparty_company_ogrn"
                              type="text"
                              label={t('ОГРН')}
                              hasError={
                                errors.counterparty_company_ogrn != null &&
                                touched.counterparty_company_ogrn != null
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              defaultValue={values.counterparty_company_ogrn}
                              value={values.counterparty_company_ogrn}
                              disabled={isViewOnly}
                            />
                          </>
                        )}
                        <TextField
                          id="counterparty_inn"
                          type="text"
                          label={t('ИНН')}
                          hasError={
                            errors.counterparty_inn != null &&
                            touched.counterparty_inn != null
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          defaultValue={values.counterparty_inn}
                          value={values.counterparty_inn}
                          disabled={isViewOnly}
                        />
                      </fieldset>
                      <fieldset className={classNames(styles.fieldsContainer)}>
                        <h4 className={'mb-4'}>{t('Контактные данные')}</h4>

                        {renderDadata(values?.counterparty_legal_address) && (
                          <DadataBaseField
                            key={forceUpdate + 'counterparty_legal_address'}
                            id="counterparty_legal_address"
                            inputProps={{
                              onChange: handleChange,
                              onBlur: handleBlur,
                              required: true,
                              disabled: isViewOnly,
                            }}
                            label={t('Юридический адрес')}
                            onChangeSelect={(
                              suggestion?: DaDataAddressSuggestion
                            ) => {
                              setForceUpdate((state: number) => ++state);
                              suggestion &&
                                setFieldValue(
                                  'counterparty_legal_address',
                                  suggestion.value
                                );
                            }}
                            typeSuggestion={TypeSuggestion.address}
                            defaultValue={values.counterparty_legal_address}
                            hasError={
                              errors.counterparty_legal_address != null &&
                              touched.counterparty_legal_address != null
                            }
                          />
                        )}

                        {renderDadata(values?.counterparty_actual_address) && (
                          <DadataBaseField
                            key={forceUpdate + 'counterparty_actual_address'}
                            id="counterparty_actual_address"
                            inputProps={{
                              onChange: handleChange,
                              onBlur: handleBlur,
                              required: true,
                              disabled: isViewOnly,
                            }}
                            label={t('Фактический адрес')}
                            onChangeSelect={(
                              suggestion?: DaDataAddressSuggestion
                            ) => {
                              setForceUpdate((state: number) => ++state);

                              suggestion &&
                                setFieldValue(
                                  'counterparty_actual_address',
                                  suggestion.value
                                );
                            }}
                            typeSuggestion={TypeSuggestion.address}
                            defaultValue={values.counterparty_actual_address}
                            hasError={
                              errors.counterparty_actual_address != null &&
                              touched.counterparty_actual_address != null
                            }
                            buttonAddon={{
                              handleClickAddon: () => {
                                setForceUpdate((state: number) => ++state);

                                setFieldValue(
                                  'counterparty_actual_address',
                                  values.counterparty_legal_address
                                );
                                values.counterparty_actual_address =
                                  values.counterparty_legal_address;
                              },

                              textButton: 'Идентичный',
                            }}
                          />
                        )}

                        <PhoneField
                          id="counterparty_phone_number"
                          label={t('Tелефон')}
                          hasError={
                            errors.counterparty_phone_number != null &&
                            touched.counterparty_phone_number != null
                          }
                          onBlur={handleBlur}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(
                              'counterparty_phone_number',
                              convertToServerPhoneNumber(event.target.value)
                            )
                          }
                          required={true}
                          defaultValue={values?.counterparty_phone_number}
                          value={values?.counterparty_phone_number}
                          disabled={isViewOnly}
                        />

                        <TextField
                          id="counterparty_email"
                          autoComplete="email"
                          type="email"
                          label={t('Email')}
                          hasError={
                            errors.counterparty_email != null &&
                            touched.counterparty_email != null
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          defaultValue={values.counterparty_email}
                          value={values.counterparty_email}
                          disabled={isViewOnly}
                        />
                      </fieldset>

                      <TextField
                        id="counterparty_description"
                        type="textarea"
                        label={t('Описание')}
                        hasError={
                          errors.counterparty_description != null &&
                          touched.counterparty_description != null
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        defaultValue={values.counterparty_description}
                        value={values.counterparty_description}
                        disabled={isViewOnly}
                      />
                    </>

                    {!isViewOnly && (
                      <FormGroup className="d-block text-right mt-4 pb-4">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {t('Сохранить')}
                        </Button>
                      </FormGroup>
                    )}
                  </div>
                </div>
              </Form>
            )}
            {isLoading && <LoaderFetch />}
          </>
        )}
      </Formik>
    </>
  );
};

export default memo(CounterpartyForm);
